'use client';

import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { RouterOutputs } from '@pegase/api';

import { CardCarousel } from '~/shared/plugins/carousel';
import { BookingRealizationImage } from '../booking/realization/booking-realization-image';

interface Props {
  data: RouterOutputs['bookingRealization']['infinite'];
}

export const FeaturedRealizations = (props: Props) => {
  const [forwardList, backwardList] = React.useMemo(() => {
    const list1: RouterOutputs['bookingRealization']['infinite']['items'] = [];
    const list2: RouterOutputs['bookingRealization']['infinite']['items'] = [];

    props.data.items.forEach((element, index) => {
      if (index % 2 === 0) {
        list1.push(element);
      } else {
        list2.push(element);
      }
    });

    return [list1, list2];
  }, [props.data]);

  return (
    <Box
      component="section"
      sx={(theme) => ({
        background: 'linear-gradient(#FFF 0%, var(--mui-palette-brand-30) 100%)',
        py: 7,
        overflow: 'hidden',
        ...theme.applyStyles('dark', {
          background: 'linear-gradient(var(--mui-palette-brand-30) 0%, #000 100%)',
        }),
      })}>
      <Box sx={{ mx: 'auto' }}>
        <Typography
          component="h2"
          variant="h4"
          fontWeight={700}
          sx={(theme) => ({
            color: 'primary.main',
            mb: 5,
            textAlign: 'center',
            ...theme.applyStyles('dark', {
              color: 'inherit',
            }),
          })}>
          Finding the best hairdresser has never been easier
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <CardCarousel
          autoplay
          displayDots={false}
          options={{ loop: true, align: 'center' }}
          sxSlide={{ flex: { xs: '0 0 100%', sm: '0 0 50%', md: '0 0 33.3%', lg: '0 0 25%', xl: '0 0 20%' } }}
          cards={forwardList.map((realization) => <BookingRealizationImage key={realization.id} realization={realization} />)}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <CardCarousel
          autoplay
          displayDots={false}
          options={{ loop: true, direction: 'rtl' }}
          sxSlide={{ flex: { xs: '0 0 100%', sm: '0 0 50%', md: '0 0 33.3%', lg: '0 0 25%', xl: '0 0 20%' } }}
          cards={backwardList.map((realization) => <BookingRealizationImage key={realization.id} realization={realization} />)}
        />
      </Box>
    </Box>
  );
};
